import api from "@/services/api";
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class ConfirmEmail extends Vue {
	isLoading: boolean = true;
	token: string = "";
	checkEmailResult: boolean = false;

	created() {
		var token = this.$route.query.token as string;
		console.log(token);

		this.setEmailConfirm(token);
	}

	setEmailConfirm(token) {
		var self = this;
		api.chimataPost<boolean>(`${api.endpoint.ACCOUNT.CONFERMA_EMAIL}/${token}`, null)
			.then(res => {
				if (res && res.data) {
					self.checkEmailResult = true;
				}
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				self.isLoading = false;
			}) ;
	}
}
